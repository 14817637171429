.page-not-found-container {
    background: #246aa1;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;

    .page-not-found-header {
        font-size: 2.5rem;
        margin: 0;
        text-align: center;
    }

    .page-not-found-description {
        font-size: 1.3rem;
        text-align: center;
    }
}