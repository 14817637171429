.home-container {
    background: #246aa1;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;

    .home-header {
        font-size: 3.5rem;
        margin: 0;
    }

    .home-description {
        font-size: 1.5rem;
    }
}